const ALBUM_BUCKET_NAME = 'whoisthisbucket';
const IDENTITY_POOL_ID = 'eu-west-2:bcd5ef80-90d8-4dfb-a17e-bfa52b7ba38d';

export default class AWSLibrary {

    constructor() {
        AWS.config.update({
            region: 'eu-west-2',
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTITY_POOL_ID
            })
        });

        const cognitoCredentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: IDENTITY_POOL_ID });

        this.rekognition = new AWS.Rekognition({
            region: 'eu-west-1',
            credentials: cognitoCredentials
        });

        this.s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            region: 'eu-west-2',
            credentials: cognitoCredentials,
            params: { Bucket: ALBUM_BUCKET_NAME }
        });
    }

    _uploadPhoto(imageName, imageb64) {
        const base64Data = new Buffer.from(imageb64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        const type = imageb64.split(';')[0].split('/')[1];

        return new Promise((resolve, reject) => {
            this.s3.upload({
                Key: imageName,
                Body: base64Data,
                ContentEncoding: 'base64',
                ContentType: `image/${type}`
            }, (err, data) => {
                if (err) {
                    return reject(err);
                }
                return resolve(data);
            })
        });
    }

    _runFaceRecognition(imageName) {
        console.log("face image");
        return new Promise((resolve, reject) => {
            const params = {
                Image: {
                    S3Object: {
                        Bucket: ALBUM_BUCKET_NAME,
                        Name: imageName
                    }
                }
            };

            this.rekognition.recognizeCelebrities(params, (err, data) => {
                if (err) {
                    return reject(err);
                }
                return resolve(data);
            });
        });
    }

    async processImage(imageName, image) {
        try {
            const s3Data = await this._uploadPhoto(imageName, image);
            const faceData = await this._runFaceRecognition(imageName);
            console.log("data image");
            return {
                s3Data: s3Data,
                faceData: faceData
            };
        }
        catch (error) {
            return {
                error: error
            };
        }
    }
}